<template>
    <v-card dense>
            <v-card-title class="title font-weight-light" >                  
                <s-toolbar color="primary" close dark save @save="verificar()" @close="close()" label="PREPACKING LIST DE PRODUCTO
                    TERMINADO DE FRUTA
                    CONGELADA         ">    
                </s-toolbar>
            </v-card-title>
            <v-card-text>
                <v-row >
                    <v-col  cols="auto" hidden >
                        <s-date label="Fecha:" v-model="item.CppDateCreate"></s-date>
                    </v-col>
                    <v-col cols="auto">
                        <s-select label="Campaña "  
                            item-value="CmaID"
                            item-text="CmaName" 
                            :items="itemsCampaigns"
                            v-model="SelectCampaign"
                            @input="SelectItemCampaing()" ></s-select>
                    </v-col>
                    <v-col cols="auto">                        
                        <s-select-client
                            checkList
                            selected
                            clearable
                            label="Cliente(es):"
                            v-model="ListClient"
                            ref="txtCumID"
                            full 
                            :disabled="DisabledCliente"
                            @input="onValue($event)">
                        </s-select-client>  
                    </v-col>
                    <v-col cols="auto" v-if="this.ListClient.length == 1">
                        <s-select-brands
                            clearable
                            label="Marcas"
                            v-model="CbdID"
                            ref="txtBrands"
                            :cumID="item.CumID"
                            full 
                            @input="filterBrands()">
                        </s-select-brands>   
                    </v-col>
                    <v-col cols="auto" >
                        <s-text label=" N° de Embarque: " :min="1" v-model="item.numberShipment" number></s-text>
                    </v-col>
                    <v-col cols="auto">
                        <s-text label="Cantidad de cajas" v-model="NumberBox">Cajas Seleccionadas</s-text>
                    </v-col>
                    
                </v-row>
                
                <v-row >
                    <v-col cols="auto">
                        <s-text label=" N° Versión "  :min="1" v-model="item.versionnumber" number></s-text>
                    </v-col>           
                    
                    
                    <v-col cols="auto">
                        <s-textarea label="Motivo de Cambio:" v-model="item.reasonChange"></s-textarea>
                    </v-col>                    
                         
                    <v-col cols="auto">
                        
                        <s-select-definition 
                            checkList                            
                            :def=1173 label="Fruta / Variedad / Cultivo:"    
                            clearable      
                            selected  
                            v-model="ListTypesCultive"
                            @input="filterBrands()">
                        </s-select-definition>  
                    </v-col>

                    <v-col cols="auto">                        
                        <s-select-definition
                            checkList
                            label="Presentación(es):"
                            :def="1429"
                            clearable
                            v-model="ListTypesCut"
                            @input="filterBrands()"
                        ></s-select-definition>
                    </v-col>              
                    
                    <v-col cols="auto">                        
                        <s-select-definition
                            checkList
                            label="Empaque(es):"
                            v-model="ListTypesPacking"
                            clearable
                            :def="1435"
                            @input="filterBrands()"
                        ></s-select-definition>
                    </v-col>
                </v-row>
                <v-row>
                    <!-- <v-col>
                        <s-scanner-qr
                            :config="configScann"
                            return-object
                            :autofocus="false"
                            @onValue="onValue($event)"
                            :readonly="false"
                            :clearInput="clearInput"
                        ></s-scanner-qr>
                    </v-col> -->
                </v-row>
                <v-row>
                    <v-col cols="auto">
                        <s-select-definition 
                            label="Tipo de PrePacking"
                            :def="1462"
                            v-model="TypePrePacking">
                            
                        </s-select-definition>
                    </v-col>
                </v-row>
                <v-row  >
                    <v-col cols="12">
                        
                        <v-data-table
                            dense
                            :headers="headerPrepacking"
                            :items="ItemsPrepackingDetail"
                            :items-per-page="-1"
                            item-key="CidID"
                            disable-sort
                            show-select    
                            single-expand                       
                            v-model="SelectPrepackingDetail"
                            group-by="PalletGroupID"
                            show-expand
                            
                            >
                            <template v-slot:item.TotalBox="{ item }">
                                <s-text style="max-width: 80px" 
                                    v-model="item.TotalBox"
                                    number
                                    :min=0
                                    @input="UpdateWeight(item)">

                                </s-text>
                            </template>
                            <template v-slot:item.IsObserved="{ item }">
                                <tr v-if="item.IsObserved == 1">
                                <v-chip x-small class="ma-2" color="red" text-color="white" @click="CargarObservacion(item)">
                                        SI
                                </v-chip>
                                </tr>
                                <tr v-if="item.IsObserved != 1">
                                <v-chip x-small class="ma-2" color="green" style="font-size: 10px;font-weight: bold;" text-color="white">
                                        NO
                                </v-chip>
                                </tr>
                            </template>
                            <!-- <template  v-slot:item.Actions="{ item }">
                                <v-btn
                                    v-if="item.CpdID"
                                    @click="clickDeleteItemGP(item)"
                                    x-small                                    
                                    fab                                    
                                    color="error"
                                >
                                <v-icon style="font-size: 16px !important"
                                >mdi-close</v-icon
                                >
                                </v-btn>
                            </template> -->
                            <template v-slot:expanded-item="{ headers, item }">
                                <td :colspan="headers.length">
                                    <div class="row">
                                        <div class="col-auto">
                                            <h6>Observacion</h6>{{ item.Observation }}
                                        </div>
                                        <div class="col">
                                            <h6>Fecha Vencimiento</h6>
                                            {{ item.DueDateString }}
                                        </div>
                                    </div>
                                </td>
                            </template>
                        </v-data-table>
                    </v-col>
                    
                </v-row>
                <v-row v-if="TypePrePacking == 2">
                    <v-col cols="auto">
                        <v-data-table
                            dense
                            :items="SelectPrepackingDetail"
                            :headers="headerPaletized">
                            <template v-slot:item.CidIDPaletized="{ item }">
                                <s-text style="max-width: 80px" 
                                    v-model="item.CidIDPaletized"
                                    number
                                    :readonly="item.Exclusive"
                                    :min=0>

                                </s-text>
                            </template>
                            <template v-slot:item.TotalBoxP="{ item }">
                                <s-text style="max-width: 80px" 
                                    v-model="item.TotalBoxP"
                                    number                                    
                                    :readonly="item.Exclusive"
                                    @input="ChangeTotalBoxP(item)"
                                    :min=0>

                                </s-text>
                            </template>
                            <template v-slot:item.TotalBagWeightP="{ item }">
                                <s-text style="max-width: 80px" 
                                    v-model="item.TotalBagWeightP"
                                    number

                                    :readonly="item.Exclusive"
                                    :min=0>

                                </s-text>
                            </template>
                            <template v-slot:item.Exclusive="{ item }">
                                <v-checkbox 
                                    v-model="item.Exclusive"
                                    @click="ClickExclusive(item)"
                                    >

                                </v-checkbox>
                            </template>
                        </v-data-table>
                    </v-col>
                </v-row>
                <!-- <v-row v-if="TypePrePacking == 2">
                    <v-col cols="12">
                        <v-data-table 
                            dense
                            :items="ItemsPrepacking" 
                            show-select
                            :items-per-page="-1"
                            item-key="CipID"
                            :headers="ItemsPrepackingHeader"
                            >
                        </v-data-table>
                    </v-col>
                </v-row> -->
            </v-card-text>
            <v-card-actions>

            </v-card-actions>
            <v-dialog v-if="DialogDetailPrepacking" v-model="DialogDetailPrepacking" max-width="800" >
                <v-card title="Pruebas"  outlined tile>
                    <s-toolbar color="primary" dark label="Listado para el PrePacking">

                    </s-toolbar>
                    <v-divider class="mt-3"></v-divider>
                    <v-card-text>
                        <v-row>
                            <v-col><strong>Fruta</strong></v-col>
                            <v-col><strong>Variedad</strong></v-col>
                            <v-col><strong>Tipo de Corte</strong></v-col>
                            <v-col><strong>Envasado</strong></v-col>
                            <v-col><strong>Cantidad de Cajas</strong></v-col>
                            <v-col><strong>Peso</strong></v-col>
                        </v-row>
                        <v-row v-for="(item, index) in cultivosDetail" :key="index">                     
                            <v-col>{{ item.TypeCultiveName }}</v-col>
                            <v-col>{{ item.VrtDescription }}</v-col>
                            <v-col>{{ item.TypeCutName }}</v-col>
                            <v-col>{{ item.TypePresentationName }}</v-col>
                            <v-col>{{ item.TotalBox }}</v-col>                         
                            <v-col>{{ item.TotalBagWeight }}</v-col>
                        </v-row>
                        <v-row>
                            <v-col><strong>Total de Cajas</strong></v-col>
                            <v-col><strong>{{ TotalCajas }}</strong></v-col>
                            <v-col><strong>Peso Total de Cajas</strong></v-col>                    
                            <v-col><strong>{{ TotalPesoCajas }}</strong></v-col>
                        </v-row>
                    </v-card-text>
                    <v-divider class="mt-3"></v-divider>
                    <v-card-actions>
                        <v-btn @click="save()">Guardar</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn  color="surface-variant" @click="DialogDetailPrepacking = false">Cerrar</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-dialog 
                v-model="dialogObservation"
                v-if="dialogObservation"
                max-width="350"
                >
                <v-card outlined>
                    <!-- <v-card-title class="title font-weight-light" >     -->
                    <s-toolbar color="primary" dark close @close="dialogObservation = false" label="Descripcion de Observacion">

                    </s-toolbar>
                    <!-- </v-card-title> -->
                    <v-card-text>
                        <v-row class="s-col-form">
                            <v-col>
                                <v-row>
                                    <v-col>
                                        <h2>Observacion: </h2>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col>
                                        <h3>{{ this.ObservedString }}</h3>
                                    </v-col>                                    
                                </v-row>
                            </v-col>
                        </v-row>
                        
                       
                    </v-card-text>
                </v-card>
            </v-dialog>
        </v-card>
    
</template>

<script>

    import _sPalletChamber from "@/services/FrozenProduction/FrzIncomePalletChamber.js"
    import SSelectClient from "@/components/FrozenProduction/Maturation/SSelectClienteMaturation";
    import _sPrePacking from "@/services/FrozenProduction/FrzChamberPrePacking.js";
    import SSelectBrands from "@/components/FrozenProduction/Chamber/SSelectBrandChamber";

    export default{
        components: { SSelectClient, SSelectBrands  },
       
        props: {
            SelectPrePacking:{
                default: null,
                type:Object,
            }
        },
        data(){
            return {
                expanded: [],
                configScann: {},
				clearInput:false,
                headerPrepacking: [
                    { text: "N°", value: "PalletGroupID" },
                    { text: "CÓDIGO DE PRODUCTO", value: "TraceabilityID" },
                    { text: "CLIENTE", value: "CumName"},
                    { text: "CULTIVO", value: "TypeCultiveName"},
                    { text: "VARIEDAD", value: "VrtDescription"},                    
                    { text: "HORA", value: "Hours"},
                    { text: "N° DE CAJAS", value: "TotalBox", width: "100px"  },
                    { text: "PESO", value: "TotalBagWeight" },
                    // { text: "LOTE CLIENTE", value: "lote" },
                    { text: "Corte", value: "TypeCutName" },
                    { text: "Presentacion", value: "TypePresentationName" },
                    // { text: "FECHA DE VENCIMIENTO", value: "DueDateString" },                   
                    { text: "T. Congelamiento", value: "CecDescription"},
                    { text: "UBICACION", value: "PalletLocation" },
                    { text: "E. Final", value: "StateFinalString" },
                    { text: "Observado", value: "IsObserved"},
                    // { text: "Observación" , value: "Observation" },
                    // { text: "OBSERVACIONES (Marca/Presentacion/Codigo SAP)", value: "PocObservation" },
                    // { text: "", value: "Actions" },
                    
                ],
                headerPaletized: [
                    { text: "ID", value: "CidID" },
                    { text: "N°", value: "PalletGroupID" },
                    { text: "CÓDIGO DE PRODUCTO", value: "TraceabilityID" },
                    { text: "CULTIVO", value: "TypeCultiveName"},
                    { text: "VARIEDAD", value: "VrtDescription"},                    
                    
                    { text: "N° DE CAJAS", value: "TotalBox", width: "100px"  },
                    { text: "PESO", value: "TotalBagWeight" },
                    { text: "ID PALETIZAR", value: "CidIDPaletized", width: "100px"},
                    { text: "N° CAJAS", value: "TotalBoxP", width: "100px"},
                    { text: "PESO", value: "TotalBagWeightP", width: "100px"},
                    { text: "", value: "Exclusive"}
                   
                ],
                ItemsPrepacking: [],
                ItemsPrepackingDetail:[],
                item: {},
                NumberBox: 0,
                SelectPrepackingDetail:[],
                ListTypesCultive: [],
                ListTypesCut: [],
                ListTypesPacking: [],
                ListClient: [],
                itemsCampaigns: [],
                SelectCampaign: 0,
                CbdID : 0,
                CumID: 0,
                TypePrePacking: 0,
                itemsDeletePG: [],
                DisabledCliente: false,
                Editar: false,
                DialogDetailPrepacking:false,
                ListPackingDetails: [],
                ItemsPrepackingDetailEdit: [],
                BoxNumber: 0,
                PesoBoxUnit: 0,
                TotalCajas: 0,
                TotalPesoCajas: 0,
                cultivosDetail: [],
                uniqueItems: [],
                ObservedString: "",
                dialogObservation: false,
                itemPaletized: {},
            }
        },
        watch:{
            SelectPrepackingDetail(){
                this.CalcularNumberBox();                
            },
            
        },
        methods:{
            // clickDeleteItemGP(item) {                
            //     this.ItemsPrepackingDetail = this.ItemsPrepackingDetail.filter(
            //         (x) => {
            //         return x.CidID != item.CidID
            //         }
            //     );
            //     item.SecStatus = 0;
            //     if(item.CpdID > 0)
            //     {
            //         this.itemsDeletePG.push({ ...item} );                    
            //     }
                
            // },
            ChangeTotalBoxP(item)
            {
                let boxes = parseInt(0);
                this.SelectPrepackingDetail.forEach(element => {
                    if(element.TotalBoxP != "")
                    {
                        boxes = boxes + parseInt(element.TotalBoxP);
                    }
                });
                console.log(this.itemPaletized, boxes);
                if(boxes > this.itemPaletized.TotalBox)
                {
                    item.TotalBoxP = 0;
                    item.TotalBagWeightP = 0;
                    this.$fun.alert("Esta Superando el numero de cajas Totales", "warning");
                    return;
                }
                item.TotalBagWeightP = item.TotalBoxP * this.PesoBoxUnit;
            },
            CargarObservacion(item)
            {
                
                this.ObservedString = item.ObservedString;
                this.dialogObservation = true;

            },
            closedialogObservation()
            {
                this.ObservedString = "";
                this.dialogObservation = false;
            },
            SelectItemCampaing(){
                
            },
           
            ClickExclusive(item)
            {            
                this.itemPaletized = item;
                item.CidIDPaletized = "";
                this.PesoBoxUnit = item.TotalBagWeight / item.TotalBox;
             
                this.SelectPrepackingDetail.forEach(element => {
                    if(element.CidID != item.CidID)
                    {                       
                        element.CidIDPaletized = item.CidID;
                        element.TotalBagWeightP = 0;
                        element.TotalBoxP      = 0;
                        element.Exclusive   = false;

                    }
                });
            },
            filterBrands()
            {
                if(this.ListClient.length == 1)
                {
                    this.CumID = this.ListClient[0];
                    this.item.CumID = this.CumID;
                    this.onValue(this.CumID);
                }
                // else
                // {
                //     this.CumID = 0;
                //     this.item.CumID = this.CumID;
                // }
                
            },            
            CalcularNumberBox(){
                this.NumberBox = 0;
                this.SelectPrepackingDetail.forEach(element => {
                    this.NumberBox = this.NumberBox + parseInt(element.TotalBox);
                });
                
                if(this.Editar)
                {
                    this.itemsDeletePG = this.ItemsPrepackingDetailEdit.filter(element => !this.SelectPrepackingDetail.includes(element));
                    this.itemsDeletePG.forEach(element => {
                        // element.SecStatus = 0;
                    })
                }
                
              
            },
            GetCampaignsChamber(){
                _sPrePacking.GetCampaignsChamber({}, this.$fun.getUserID()).then(r => {
                    if(r.status == 200){
                        this.itemsCampaigns = r.data;
                    }
                })
            },
            onValue(val) 
            {
               
                if(this.ListClient.length > 1)
                {
                    this.CbdID = 0;
                }
                                
                if(this.ListClient.length > 0 && this.SelectCampaign == 0 )
                {
                    this.$fun.alert("Seleccione campaña para obtener correlativo", "warning");
                    return;
                }                
                            
                // this.CumID = val;
                let obj = {
                    CumID: this.CumID,
                    ListCumID: this.ListClient.toString(),
                    CbdID: this.CbdID,
                    ListTypesCultive: this.ListTypesCultive.toString(),
                    ListTypesCut: this.ListTypesCut.toString(),
                    ListTypesPacking: this.ListTypesPacking.toString(),
                    CmaID: this.SelectCampaign
                }
                
                    _sPalletChamber.returnPalett(obj, this.$fun.getUserID()).then( r => {
                        if(r.status == 200){
                            this.ItemsPrepackingDetail = [];
                            this.SelectPrepackingDetail = [];
                            this.ItemsPrepacking = r.data;   
                                     
                            r.data.forEach(element => {
                                element.palletDetailCEs.forEach((item, index) => {
                                    item.Line = index + 1;
                                    item.PalletGroupID = element.PalletGroupID;
                                    item.PalletLocation = element.PalletLocation;
                                    item.DueDateString = this.$moment(item.DueDate).format("MM/DD/YYYY");
                                    item.Hours = this.$moment(item.SecCreate).format("hh:mm");                                    
                                    // item.TypeCutName = element.TypeCutName;
                                    // item.TypePresentationName = element.TypePresentationName;
                                    item.IsObservedString = element.IsObservedString;
                                    item.TotalBagWeightOld = item.TotalBagWeight;
                                    item.TotalBoxOld = item.TotalBox;
                                    item.BoxWeighUnit = item.TotalBagWeight / item.TotalBox;
                                    item.SecStatus = 1;                                    
                                    item.CppID = this.item.CppID;
                                    // item.CecDescription = element.CecDescription;
                                    // item.PocObservation = element.PocObservation;
                                    // item.TypeCultiveName = element.TypeCultiveName;
                                    // item.VrtDescription = element.VrtDescription;
                                    this.ItemsPrepackingDetail.push(item);
                                    
                                });
                                if(!this.Editar)
                                {
                                    this.item.numberShipment  = '';  
                                    this.item.numberShipment = element.numberShipment;
                                }
                            });
                            
                            if(this.Editar)
                            {
                               
                                this.ItemsPrepackingDetailEdit.forEach(element =>{
                                    element.TypePrePacking = this.TypePrePacking;  
                                    this.ItemsPrepackingDetail.push(element);
                                    this.SelectPrepackingDetail.push(element);
                                })
                                
                            }
                        }
                    });
             
			},
            verificar(){
                this.cultivosDetail = [];
                this.TotalCajas = 0;
                this.TotalPesoCajas = 0;
                this.ListPackingDetails = this.SelectPrepackingDetail;    
                
                let ListTypeCultive = [];
                let UnicosListTypeCultive = [];

                let typeCultive = {};
                
                this.ListPackingDetails.forEach(element =>{
                    typeCultive = {};
                    typeCultive.typeCultive = element.TypeCultive;
                    typeCultive.TypePacking = element.TypePacking;
                    typeCultive.VrtID = element.VrtID;
                    typeCultive.TypeCut = element.TypeCut;
                    ListTypeCultive.push(typeCultive);
                });
                
                UnicosListTypeCultive = new Set(ListTypeCultive.map(JSON.stringify));

                this.uniqueItems = Array.from(UnicosListTypeCultive, JSON.parse);
                
                
                this.uniqueItems.forEach(item => {
                    let cultivo = {};
                    cultivo.TotalBox = 0;
                    cultivo.TotalBagWeight = 0;
                    this.ListPackingDetails.forEach(element =>{                        
                        if(element.TypeCultive == item.typeCultive && item.TypePacking == element.TypePacking && item.TypeCut == element.TypeCut && item.VrtID == element.VrtID)
                        {                            
                            cultivo.TypeCultiveName = element.TypeCultiveName;
                            cultivo.TypeCutName = element.TypeCutName;
                            cultivo.TypePresentationName = element.TypePresentationName;
                            cultivo.VrtDescription  = element.VrtDescription;
                            cultivo.TotalBox += parseInt(element.TotalBox);
                            cultivo.TotalBagWeight += element.TotalBagWeight;
                        }
                        
                    });   
                    this.cultivosDetail.push(cultivo);                 
                });

                this.cultivosDetail.forEach( r => {
                    this.TotalCajas = parseInt(this.TotalCajas) + parseInt(r.TotalBox);
                    this.TotalPesoCajas += r.TotalBagWeight;
                });
                
                this.DialogDetailPrepacking = true;
                         
            },
            save()
            {         
                
                if(this.ListClient.length == 1)
                {
                    this.item.CumID = this.ListClient[0];
                }else
                {
                    this.item.CumID = 0;
                    
                    let listclientes = [];
                    this.ListClient.forEach(r => {
                        let cliente = {};
                        cliente.CumID = r;
                        cliente.UsrCreateID = this.$fun.getUserID();
                        listclientes.push(cliente);
                       
                    });
                    this.item.ListClient = listclientes;
                }
                
                if(this.item.numberShipment == ''){
                    this.$fun.alert("Debe colocar número de embarque", "warning");
                    return;
                }
                if(this.item.versionnumber == ''){
                    this.$fun.alert("Debe colocar numero de versión", "warning");
                    return;
                }
                
                if(this.SelectPrepackingDetail.length == 0){
                    this.$fun.alert("No tiene items la lista de Pallets", "warning");
                    return;
                }
                this.item.UsrID = this.$fun.getUserID();
                
                let PalletExclusive = this.SelectPrepackingDetail.filter(x =>  x.Exclusive == true );
               
                this.SelectPrepackingDetail.forEach(element => {                    
                    element.TypePrePacking = this.TypePrePacking;  
                    PalletExclusive.forEach( x => {
                        if(element.CidIDPaletized == x.CidID)  
                        {
                            element.PPaletized = x.PalletGroupID;
                        }
                    });
                                                       
                });

                

                this.item.ListPackingDetails = [...this.SelectPrepackingDetail, ...this.itemsDeletePG];
                
                
                let TypesCultive = []
                let Cultive = {}
                this.ListTypesCultive.forEach((r, index) =>{
                    let Cultive = {}
                    Cultive.DedValue = r;
                    Cultive.UsrCreate = this.$fun.getUserID();
                    TypesCultive.push(Cultive);
                })

                let TypesCut = []
                let Cut = {}
                this.ListTypesCut.forEach((r, index) =>{
                    let Cut = {}
                    Cut.DedValue = r;
                    Cut.UsrCreate = this.$fun.getUserID();
                    TypesCut.push(Cut);
                })

                let TypesPacking = []
                let Packing = {}
                this.ListTypesPacking.forEach((r, index) =>{
                    let Packing = {}
                    Packing.DedValue = r;
                    Packing.UsrCreate = this.$fun.getUserID();
                    TypesPacking.push(Packing);
                })

                
                this.item.FrzTypesCultive = TypesCultive;
                this.item.FrzTypesCut = TypesCut;
                this.item.FrzTypesPacking = TypesPacking;
                this.item.CmaID = this.SelectCampaign;
                this.item.NumberBox = this.NumberBox;
                
          
                this.$fun.alert("Desea registrar PrePacking", "question").then( r => {
                    if(r.value)
                    {
                        this.DialogDetailPrepacking = false;
                        _sPrePacking.save(this.item, this.$fun.getUserID()).then( r => {
                            if(r.status == 200){
                                this.$fun.alert("Se registro correctamente", "success");
                                this.ItemsPrepackingDetail = [];
                                this.SelectPrepackingDetail = [];
                                this.item = {};
                                this.ListTypesCultive = [];
                                this.ListTypesCut = [];
                                this.ListTypesPacking = [];
                                this.ListClient = [];
                                this.CmaID = 0;
                                if(this.Editar)
                                {
                                    this.close();
                                }
                            }
                        });
                    }
                });
            },
            UpdateWeight(item)
            {              
                this.ItemsPrepackingDetail.forEach(r =>{
                    if (r.CidID == item.CidID) 
                    {
                        if(r.TotalBox <= r.TotalBoxOld) 
                        {
                            r.TotalBagWeight = parseFloat(r.BoxWeighUnit) * parseInt(r.TotalBox);  
                        }else{
                            this.$fun.alert("El total debe ser menor", "info");
                            r.TotalBox = r.TotalBoxOld;
                        }
                    }
                })
                this.CalcularNumberBox();
            },
            close()
            {                
                this.$emit("closeUpdate");
            }
        },
        beforeCreate()
        {
            this.ItemsPrepackingDetailEdit = [];
        },  
          
        created()
        {
            this.ItemsPrepackingDetailEdit = [];
            this.ListClient = [];
            if(this.SelectPrePacking != null)
            {           
                
                this.Editar = true;     
                this.DisabledCliente = true;
                
                //             
                
                this.SelectCampaign = this.SelectPrePacking.CmaID;
                 
                if(this.SelectPrePacking.ListClient.length > 0)
                {                    
                    this.SelectPrePacking.ListClient.forEach(r => 
                    {
                        let CumID = r.CumID;
                        this.ListClient.push(CumID);                  
                    });
                    this.onValue();
                   
                }else{
                    this.ListClient.push(this.SelectPrePacking.CumID);
                }
                this.item = this.SelectPrePacking;   
                this.ItemsPrepackingDetailEdit = this.item.ListPackingDetails;   
                
                this.ItemsPrepackingDetailEdit.forEach(element => {
                    element.DueDateString = this.$moment(element.DueDate).format("MM/DD/YYYY");
                    element.Hours = this.$moment(element.SecCreate).format("hh:mm"); 
                    element.TotalBagWeightOld = element.TotalBagWeight;
                    element.TotalBoxOld = element.TotalBox;
                    element.BoxWeighUnit = element.TotalBagWeight / element.TotalBox;
                    
                });
            }
            this.GetCampaignsChamber();
        }
    }
</script>